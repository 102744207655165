<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card>
          <b-card>
            <!-- input search -->
            <div class="custom-search d-flex justify-content-end">
              <b-form-group>
                <div class="d-flex align-items-center">
                  <label class="mr-1">Recherche
                  </label>
                  <b-form-input
                    v-model="searchTerm"
                    placeholder="Recherche"
                    type="text"
                    class="d-inline-block"
                  />
                </div>
              </b-form-group>
              <!-- primary -->
              <b-dropdown
                id="dropdown-1"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                text="Exporter"
                variant="primary"
                class="ml-2 btn_export"
              >
                <b-dropdown-item @click="exportCSV">
                  CSV
                </b-dropdown-item>
                <b-dropdown-item @click="exportExcel">
                  MS-Excel
                </b-dropdown-item>
              </b-dropdown>

              <!-- button -->
              <b-button
                id="toggle-btn"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal.zone-modal-prevent-closing
                variant="outline-primary"
                class="btn_export ml-2"
              >
                <feather-icon icon="PlusCircleIcon" />
                Ajouter une zone
              </b-button>

              <!-- modal -->
              <b-modal
                id="zone-modal-prevent-closing"
                ref="zone-modal"
                title="Nouvelle zone"
                ok-title="Enregistrer"
                cancel-variant="outline-secondary"
                cancel-title="Annuler"
                size="lg"
                @hidden="resetModal"
                @ok="handleOk"
              >
                <form
                  ref="form"
                  @submit.stop.prevent="handleSubmit"
                >
                  <b-row>
                    <b-col md="6">
                      <b-form-group
                        :state="zone.nomState"
                        label="Nom de la zone"
                        label-for="nom-input"
                        invalid-feedback="Le nom est requis"
                      >

                        <b-form-input
                          id="nom-input"
                          ref="nom"
                          v-model="zone.nom"
                          :state="zone.nomState"
                          required
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        :state="zone.regionState"
                        label="Region de la zone"
                        label-for="type-input"
                        invalid-feedback="Le region est requis"
                      >

                        <v-select
                          v-model="zone.region"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="nom"
                          :options="regions"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </form>
              </b-modal>
            </div>
            <vue-good-table
              id="list"
              ref="myTable"
              :columns="columns"
              :rows="zones"
              :rtl="direction"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm }"
              :pagination-options="paginationOptions"
              :pagination="pagination"
            >
              <template
                slot="table-row"
                slot-scope="props"
              >

                <!-- Column: Status -->
                <span v-if="props.column.field === 'status'">
                  <b-badge :variant="statusVariant(props.row.status)">
                    {{ props.row.status }}
                  </b-badge>
                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                  <!--                  <feather-icon-->
                  <!--                    icon="Edit2Icon"-->
                  <!--                    class="mr-50"-->
                  <!--                  />-->
                  <!--                  <span>Edit</span>-->
                  <b-button-group>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      :to="{ name: 'zones-details', params: { id: props.row.id } }"
                    >
                      Voir
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      @click="handleUpdate(props.row)"
                    >
                      Modifier
                    </b-button>
                  </b-button-group>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <!--              <template-->
              <!--                slot="pagination-bottom"-->
              <!--                slot-scope="props"-->
              <!--              >-->
              <!--                <div class="d-flex justify-content-between flex-wrap">-->
              <!--                  <div class="d-flex align-items-center mb-0 mt-1">-->
              <!--                    <span class="text-nowrap">-->
              <!--                      Affichage de 1 à-->
              <!--                    </span>-->
              <!--                    <b-form-select-->
              <!--                      v-model="pageLength"-->
              <!--                      :options="['3','5','10','15','20']"-->
              <!--                      class="mx-1"-->
              <!--                      @input="(value)=>props.perPageChanged({currentPerPage:value})"-->
              <!--                    />-->
              <!--                    <span class="text-nowrap "> sur {{ props.total }} élements </span>-->
              <!--                  </div>-->
              <!--                  <div>-->
              <!--                    <b-pagination-->
              <!--                      :value="1"-->
              <!--                      :total-zones="props.total"-->
              <!--                      :per-page="pageLength"-->
              <!--                      first-number-->
              <!--                      last-number-->
              <!--                      align="right"-->
              <!--                      prev-class="prev-item"-->
              <!--                      next-class="next-item"-->
              <!--                      class="mt-1 mb-0"-->
              <!--                      @input="(value)=>props.pageChanged({currentPage:value})"-->
              <!--                    >-->
              <!--                      <template #prev-text>-->
              <!--                        <feather-icon-->
              <!--                          icon="ChevronLeftIcon"-->
              <!--                          size="18"-->
              <!--                        />-->
              <!--                      </template>-->
              <!--                      <template #next-text>-->
              <!--                        <feather-icon-->
              <!--                          icon="ChevronRightIcon"-->
              <!--                          size="18"-->
              <!--                        />-->
              <!--                      </template>-->
              <!--                    </b-pagination>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </template>-->
            </vue-good-table>
          </b-card>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BBadge, BButton, BButtonGroup,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect, BModal,
  BPagination,
  BRow,
  VBModal,
} from 'bootstrap-vue'
import VueGoodTable from 'vue-good-table/src/components/Table.vue'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import htmlToPaper from 'vue-html-to-paper'
import vSelect from 'vue-select'
import { API_URL } from '@/helpers/global-scops'
import showToast, { generateConcatenatedWord } from '@/helpers/methodes'
import * as XLSX from 'xlsx'

export default {
  name: 'MainVue',
  components: {
    BButtonGroup,
    BModal,
    BButton,
    BBadge,
    VueGoodTable,
    BCard,
    BCol,
    BRow,
    BDropdown,
    BFormInput,
    BDropdownItem,
    BFormGroup,
    BFormSelect,
    BPagination,
    vSelect,
  },
  directives: {
    Ripple,
    htmlToPaper,
    'b-modal': VBModal,
  },
  data() {
    return {
      paginationOptions: {
        enabled: true,
        perPageDropdown: [10, 20, 50],
        nextLabel: 'Suivant',
        prevLabel: 'Précédent',
        rowsPerPageLabel: 'Résultats par page',
        ofLabel: 'de',
        pageLabel: 'Page',
        allLabel: 'Tout',
      },
      pagination: {
        enabled: true,
        perPage: 10,
      },
      regions: [],
      zone: {
        nom: '',
        region: '',
        nomState: null,
        regionState: null,
      },
      pageLength: 10,
      searchTerm: '',
      columns: [
        {
          label: 'Nom',
          field: 'nom',
        },
        {
          label: 'Nombre de commerciaux',
          field: 'nbr_commercial',
        },
        {
          label: 'Nombre de distributeurs',
          field: 'nbr_distributeur',
        },
        {
          label: 'Nombre de PDV',
          field: 'nbr_pdv',
        },
        {
          label: 'region',
          field: 'region_r.nom',
        },
        {
          label: 'Actions',
          field: 'action',
        },
      ],
      zones: [],
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  created() {
    this.getZones()
    this.getRegions()
  },
  methods: {
    exportExcel() {
      const header = this.columns.map(column => column.label);
      const data = [header].concat(this.zones.map(row => this.columns.map(column => row[column.field])));

      // Créer un workbook avec une feuille
      const ws = XLSX.utils.aoa_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Export");

      // Générer le fichier et le télécharger
      const wbout = XLSX.write(wb, {bookType:'xlsx', bookSST:true, type: 'array'});
      const blob = new Blob([wbout], {type:'application/octet-stream'});
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${generateConcatenatedWord('zones')}.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    exportCSV() {
      const header = this.columns.map(column => column.label)
      const data = this.zones.map(row => this.columns.map(column => row[column.field]))
      let csvData = `${header.join(',')}\n`
      csvData += data.map(row => row.join(',')).join('\n')
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.setAttribute('href', url)
      link.setAttribute('download', `${generateConcatenatedWord('zones')}.csv`)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },

    checkFormValidity() {
      this.zone.regionState = !!this.zone.region
      this.zone.nomState = this.zone.nom.length > 0
      return this.zone.nomState && this.zone.nomState && this.zone.regionState
    },
    resetModal() {
      this.zone.id = null
      this.zone.nom = ''
      this.zone.nomState = null
      this.zone.region = ''
      this.zone.regionState = null
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      this.saveZone()
    },
    getZones() {
      // localStorage.removeItem('access')
      this.$http.get(`${API_URL}zones/`).then(response => {
        this.zones = response.data
      })
        .catch(error => {
          console.log(error)
        })
    },
    getRegions() {
      this.$http.get(`${API_URL}regions/`)
        .then(response => {
          this.regions = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    saveZone() {
      if (!this.zone.id && this.zone.id == null) {
        this.$http.post(`${API_URL}zones/`, this.zone).then(() => {
          showToast(this, 'info', 'Ajout', 'Element ajouté')
          this.getZones()
          // Hide the modal manually
          this.$nextTick(() => {
            this.$refs['zone-modal'].toggle('#toggle-btn')
          })
        }).catch(error => {
          console.log(error)
        })
      } else {
        this.zone.region = this.zone.region.id
        this.$http.put(`${API_URL}zones/${this.zone.id}/`, this.zone).then(() => {
          showToast(this, 'info', 'Ajout', 'Element modifié')
          this.getZones()
          // Hide the modal manually
          this.$nextTick(() => {
            this.$refs['zone-modal'].toggle('#toggle-btn')
          })
        }).catch(error => {
          console.log(error)
        })
      }
    },
    handleDelete(id) {
      // eslint-disable-next-line no-restricted-globals,no-alert
      if (confirm('Êtes-vous sûr de vouloir supprimer cet élément ?')) {
        this.$http.delete(`${API_URL}zones/${id}/`).then(response => {
          console.log(response.data)
          showToast(this, 'danger', 'Suppression', 'Element supprimé')
          this.getZones()
        }).catch(error => {
          console.log(error)
        })
      } else {
        // Annuler la suppression ici
      }
    },
    handleUpdate(item) {
      console.log(item)
      this.zone.nom = item.nom
      this.zone.id = item.id
      this.zone.region = item.region_r
      this.$refs['zone-modal'].toggle('#toggle-btn')
    },
  },
}
</script>

<style scoped>

</style>
